.orange-button {
  margin-left: 300px;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 3px solid var(--primary);
  opacity: 0px;
  background: linear-gradient(360deg, #f97316 0%, #fdba74 100%);
  color: white;
  font-size: 16px;
  box-shadow: 0px 0px 16px 0px #fb923c80;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: linear-gradient(360deg, #ea580c 0%, #fb923c 100%);
    border: 3px solid #fb923c;
    box-shadow: 0px 0px 16px 0px #fb923c80;
  }
  &:disabled {
    background: #fed7aa;
    border: 3px solid #fed7aa;
    box-shadow: none;
    cursor: auto;
  }
}

.stroked-orange-button{
  width: 140px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  border-radius: 8px;
  border: 1px solid var(--primary);
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  &:hover{
    background-color: var(--primary-50);
  }
}

.pointer {
  cursor: pointer;
}

.blue-button {
  border-radius: 8px;
  padding: 12px 40px;
  border: 3px solid var(--secondary-100);
  opacity: 1;
  background: linear-gradient(360deg, #0891b2 0%, #22d3ee 100%);
  color: white;
  font-size: 16px;
  box-shadow: 0px 0px 16px 0px #67e8f940;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background: linear-gradient(360deg, #0e7490 0%, #06b6d4 100%);
    border: 3px solid #cffafe;
  }
}