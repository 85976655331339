
@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-Bold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-SemiBold.woff2') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-Medium.woff2') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-Regular.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-RegularItalic.woff2') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../../assets/fonts/Metropolis-Light.woff2') format('truetype');
  font-weight: 300;
  font-style: normal;
}

$font: 'Metropolis', sans-serif;

@mixin base-typo($size, $weight, $spacing) {
    font-weight: $weight;
    font-size: $size;
    letter-spacing: $spacing;
  }

  :root {
    --font: #{$font};
  }