
// Import material theming functions
@use 'SASS:map';
@use '@angular/material' as mat;


$md-primary: (
    50 :  #FFF7ED,
    100 : #FFEDD5,
    200 : #FED7AA,
    300 : #FDBA74,
    400 : #FB923C,
    500 : #F97316,
    600 : #EA580C,
    700 : #C2410C,
    800 : #9A3412,
    900 : #7C2D12,
    950 : #431407,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$md-secondary: (
    50 :  #ECFEFF,
    100 : #CFFAFE,
    200 : #A5F3FC,
    300 : #67E8F9,
    400 : #22D3EE,
    500 : #06B6D4,
    600 : #0891B2,
    700 : #0E7490,
    800 : #155E75,
    900 : #164E63,
    950 : #083344,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-gray-palette: (
    50 :  #F9FAFB,
    100 : #F3F4F6,
    200 : #E5E7EB,
    300 : #D1D5DB,
    400 : #9CA3AF,
    500 : #6B7280,
    600 : #4B5563,
    700 : #374151,
    800 : #1F2937,
    900 : #111827,
    950 : #030712,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//GLOBAL

$my-primary: mat.m2-define-palette($md-primary, 500);
$my-secondary: mat.m2-define-palette($md-secondary, 500);
$my-warn: mat.m2-define-palette(mat.$m2-gray-palette);
$my-theme: mat.m2-define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-secondary,
   warn: $my-warn,
 )
));
@include mat.all-component-themes($my-theme);
/* @include mat.core-theme($my-theme);
@include mat.button-theme($my-theme); */

//Colores de material
$color-config: mat.m2-get-color-config($my-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:  map.get($color-config, 'warn');
$primary: mat.m2-get-color-from-palette($primary-palette, 500);
$secondary: mat.m2-get-color-from-palette($accent-palette, 500);
$light-secondary: mat.m2-get-color-from-palette($accent-palette, 300);
$light-primary: mat.m2-get-color-from-palette($primary-palette, 300);
$warn: mat.m2-get-color-from-palette($warn-palette);
$secondary-900: mat.m2-get-color-from-palette($accent-palette, 900);
$gray-palette-100: mat.m2-get-color-from-palette($warn-palette, 100);
$gray-palette-400: mat.m2-get-color-from-palette($warn-palette, 400);
$gray-palette-500: mat.m2-get-color-from-palette($warn-palette, 500);
$secondary-600: mat.m2-get-color-from-palette($accent-palette, 600);
$gray-palette-800: mat.m2-get-color-from-palette($warn-palette, 800);
$secondary-100: mat.m2-get-color-from-palette($accent-palette, 100);
$secondary-50: mat.m2-get-color-from-palette($accent-palette, 50);
$secondary-800: mat.m2-get-color-from-palette($accent-palette, 800);
$primary-300: mat.m2-get-color-from-palette($primary-palette, 300);
$secondary-400: mat.m2-get-color-from-palette($accent-palette, 400);
$secondary-200: mat.m2-get-color-from-palette($accent-palette, 200);
$secondary-500: mat.m2-get-color-from-palette($accent-palette, 500);
$primary-50: mat.m2-get-color-from-palette($primary-palette, 50);
$gray-palette-50: mat.m2-get-color-from-palette($warn-palette, 50);
//COLORES GLOBALES

:root {
    --primary: #{$primary};
    --secondary: #{$secondary};
    --light-secondary: #{$light-secondary};
    --light-primary: #{$light-primary};
    --warn: #{$warn};
    --secondary-900: #{$secondary-900};
    --gray-palette-100: #{$gray-palette-100};
    --gray-palette-400: #{$gray-palette-400};
    --gray-palette-500: #{$gray-palette-500};
    --secondary-600: #{$secondary-600};
    --gray-palette-800: #{$gray-palette-800};
    --secondary-100: #{$secondary-100};
    --secondary-50: #{$secondary-50};
    --secondary-800: #{$secondary-800};
    --primary-300: #{$primary-300};
    --secondary-400: #{$secondary-400};
    --secondary-200: #{$secondary-200};
    --secondary-500: #{$secondary-500};
    --primary-50: #{$primary-50};
    --gray-palette-50: #{$gray-palette-50};
}

